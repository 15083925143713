/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'Bosch SerifB';
    src: url('../fonts/BoschSerif-Bold.eot');
    src: url('../fonts/BoschSerif-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BoschSerif-Bold.woff') format('woff'),
        url('../fonts/BoschSerif-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bosch SerifI';
    src: url('../fonts/BoschSerif-Italic.eot');
    src: url('../fonts/BoschSerif-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BoschSerif-Italic.woff') format('woff'),
        url('../fonts/BoschSerif-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Bosch SerifR';
    src: url('../fonts/BoschSerif-Regular.eot');
    src: url('../fonts/BoschSerif-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BoschSerif-Regular.woff') format('woff'),
        url('../fonts/BoschSerif-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bosch SerifBI';
    src: url('../fonts/BoschSerif-BoldItalic.eot');
    src: url('../fonts/BoschSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BoschSerif-BoldItalic.woff') format('woff'),
        url('../fonts/BoschSerif-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
	font-family: 'MontserratL';
	src: url('../fonts/Montserrat-Light.eot');
	src: local('../fonts/Montserrat Light'), local('Montserrat-Light'),
		url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Light.woff') format('woff'),
		url('../fonts/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'MontserratMI';
	src: url('../fonts/Montserrat-MediumItalic.eot');
	src: local('../fonts/Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
		url('../fonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-MediumItalic.woff') format('woff'),
		url('../fonts/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Thin.eot');
	src: local('../fonts/Montserrat Thin'), local('Montserrat-Thin'),
		url('../fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Thin.woff') format('woff'),
		url('../fonts/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-ExtraLightItalic.eot');
	src: local('../fonts/Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
		url('../fonts/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
		url('../fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-BoldItalic.eot');
	src: local('../fonts/Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
		url('Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-BoldItalic.woff') format('woff'),
		url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'MontserratSB';
	src: url('../fonts/Montserrat-SemiBold.eot');
	src: local('../fonts/Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-SemiBold.woff') format('woff'),
		url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-ExtraLight.eot');
	src: local('../fonts/Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
		url('../fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-ExtraLight.woff') format('woff'),
		url('../fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-ExtraBoldItalic.eot');
	src: local('../fonts/Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
		url('../fonts/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
		url('../fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Italic.eot');
	src: local('../fonts/Montserrat Italic'), local('Montserrat-Italic'),
		url('../fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Italic.woff') format('woff'),
		url('../fonts/Montserrat-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'MontserratB';
	src: url('../fonts/Montserrat-Bold.eot');
	src: local('../fonts/Montserrat Bold'), local('Montserrat-Bold'),
		url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Bold.woff') format('woff'),
		url('../fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-LightItalic.eot');
	src: local('../fonts/Montserrat Light Italic'), local('Montserrat-LightItalic'),
		url('../fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-LightItalic.woff') format('woff'),
		url('../fonts/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-BlackItalic.eot');
	src: local('../fonts/Montserrat Black Italic'), local('Montserrat-BlackItalic'),
		url('../fonts/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-BlackItalic.woff') format('woff'),
		url('../fonts/Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-SemiBoldItalic.eot');
	src: local('../fonts/Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
		url('../fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
		url('../fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'MontserratR';
	src: url('../fonts/Montserrat-Regular.eot');
	src: local('../fonts/Montserrat Regular'), local('Montserrat-Regular'),
		url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Regular.woff') format('woff'),
		url('../fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MontserratM';
	src: url('../fonts/Montserrat-Medium.eot');
	src: local('../fonts/Montserrat Medium'), local('Montserrat-Medium'),
		url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Medium.woff') format('woff'),
		url('../fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-ExtraBold.eot');
	src: local('../fonts/Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
		url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
		url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'MontserratBL';
	src: url('../fonts/Montserrat-Black.eot');
	src: local('../fonts/Montserrat Black'), local('Montserrat-Black'),
		url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Black.woff') format('woff'),
		url('../fonts/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-ThinItalic.eot');
	src: local('../fonts/Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
		url('../fonts/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-ThinItalic.woff') format('woff'),
		url('../fonts/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

$regular: 'MontserratR';
$bold: 'MontserratB';
$light: 'MontserratL';
$black: 'MontserratBL';
$medium: 'MontserratM';
$semibold: 'MontserratSB';


$boldBos: 'Bosch SerifB';
$regularBos: 'Bosch SerifR';

$color: #c2000f;
$color2: #f8b95d;

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

@mixin cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
}

@mixin contain {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center center;
}
* {
	box-sizing: border-box;
	outline: none;
}

*:after, *:before {
	display: block;
	line-height: 1;
}
img{
	border: none;
}
body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $light;
	-webkit-text-size-adjust: 100%;
	font-size: 16px;
	margin: 0;
    @media screen and (max-width: 1024px) {
		padding-top: 80px;
	}
    @media screen and (max-width: 768px) {
		display: block;
		font-size: 14px;
    }
}

h1 {
	font-family: $regularBos;
	font-size: 36px;
	color: #333333;
	line-height: 1;
	margin: 0 0 40px;
    a {
        font-size: 42px;
        color: #333333;
        &:hover {
            color: #333333;
        }
	}
	strong{
		font-family: $boldBos;
		font-size: 48px;
	}
	@media screen and (max-width: 1200px) {
        a {
            font-size: 32px;
		}
		strong{
			font-size: 40px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 30px;
        a {
            font-size: 28px
		}
		strong{
			font-size: 32px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
		}
		strong{
			font-size: 26px;
		}
	}
	@media screen and (max-width: 568px) {
        font-size: 20px;
        margin-bottom: 15px;
        a {
            font-size: 20px;
		}
		strong{
			font-size: 22px;
		}
    }
}
h2 {
	font-family: $boldBos;
	font-size: 36px;
	color: #333333;
	margin: 0 0 40px;
	text-align: center;
	a {
        font-size: 36px;
		color: #333333;
        &:hover {
            color: #333333;
        }
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 30px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
	}
	@media screen and (max-width: 568px) {
        font-size: 20px;
        margin-bottom: 15px;
    }
}
h3 {
	font-family: $boldBos;
	font-size: 24px;
	color: #333333;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
	}
    @media screen and (max-width: 20px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #333333;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #333333;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #333333;
}
a {
    color: #333333;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #333333;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #333333;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 20px 20px;
		margin-bottom: 15px;
		color: #333333;
		box-shadow: none;
		border: none;
		&::placeholder{
			color: #b4b4b4;
		}
	}
	@media screen and (max-width: 1366px) {
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"] {
			padding: 15px 20px;
			margin-bottom: 10px;
			font-size: 14px;
		}
	}
	@media screen and (max-width: 768px) {
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"] {
			padding: 10px 15px;
			margin-bottom: 10px;
			font-size: 14px;
		}
	}
}
.main {
	.container, .container2 {
		padding-top: 70px;
		padding-bottom: 70px;
		@media screen and (max-width: 1280px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 1024px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

.container {
    width: 1500px;
    padding: 0 15px;
    margin: 0 auto;
	@media screen and (max-width: 1500px) {
		width: 100%;
	}
}
.container2 {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
	@media screen and (max-width: 1170px) {
		width: 100%;
	}
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
	outline: none;
	cursor: pointer;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: $color;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
	width: auto!important;
	background-color: $color!important;
	font-family: $bold!important;
	font-size: 16px;
	color: #fff!important;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid $color;
	padding: 17px 30px!important;
	line-height: 1;
	position: relative;
	z-index: 2;
	transition: 300ms;
	&:after{
		content: '';
		position: absolute;
		top: 5px;
		right: 5px;
		left: 5px;
		bottom: 5px;
		border: 1px solid #f8b95d;
	}
	&:focus, &:active {
		background-color: $color!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #f8b95d!important;
		color: #fff!important;
		border: 2px solid #f8b95d;
		&:after{
			border: 1px solid $color;
		}
	}
	.send{
		border: none;
		background: none;
		font-size: 16px;
		font-family: $bold;
		color: #fff;
		text-transform: uppercase;
	}	
	@media screen and (max-width: 1366px) {
		padding: 13px 30px !important;
		font-size: 14px;
		.send{
			font-size: 14px;
		}
	}
	@media screen and (max-width: 768px){
		padding: 10px 25px !important;
		font-size: 12px;
		.send{
			font-size: 12px;
		}
		&:after{
			top: 2px;
			right: 2px;
			left: 2px;
			bottom: 2px;
		}
	}
	@media screen and (max-width: 568px){
		padding: 7px 25px !important;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: "\f105";
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 16px;
			color: $color2;
			left: 0;
			top: 0px;
		}
	}
}

.col--address,
.col--phone,
.col--email,
.col--mobile {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 18px;
		color: $color;
		position: absolute;
		top: 0px;
		left: 0;
	}
}
.col--address:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 14px;
	top: 2px;
}
.col--mobile:after{
	content: "\f10b";
}
.col--phone{
	a{
		pointer-events: none;
	}
	ul{
		@include ul-default;
	}
	.more--btn{
		pointer-events: auto;
	}
	@media screen and (max-width: 1024px){
		a{
			pointer-events: auto;
		}
	}
}
.col--email{
	text-decoration: underline !important;
	&:hover{
		color: $color2 !important;
	}
}
.more--btn{
	position: absolute;
	top: 2px;
	right: 0;
	font-family: 'FontAwesome';
	color: #333333;
	&:after{
		content: "\f107";
	}
	&.open{
		transform: rotate(180deg);
	}
	&:active, &:focus{
		color: #333333;
	}
}

/* header */
header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 15px 0;
	z-index: 2;
	.container{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.logo{
		width: 200px;
		height: 90px;
		>img{
			@include contain;
		}
	}
	.nav{
		display: flex;
		@include ul-default;
		>li{
			>a{
				font-family: $medium;
				font-size: 18px;
				padding: 0 20px;
				display: block;
				&:hover{
					color: $color2;
				}
			}
			&.active{
				color: $color2;
			}
		}
	}
	.col--phone{
		padding-right: 15px;
		ul{
			@include ul-default;
			position: absolute;
			display: none;
			background-color: $color;
			left: 25px;
			right: 0;
			white-space: nowrap;
			padding: 5px;
			box-shadow: 0 0 10px rgba(0,0,0,0.2);
			li{
				a{
					color: #fff;
				}
			}
		}
		span{
			font-family: $bold;
		}
	}
	.btn{
		background-color: transparent !important;
		color: $color !important;
		padding: 13px 25px !important;
		&:after{
			display: none;
		}
	}
	@media screen and (max-width: 1280px) {
		.nav{
			>li{
				>a{
					padding: 0 10px;
					font-size: 16px;
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.logo{
			width: 150px;
		}
		.btn{
			padding: 10px 20px !important;
			font-size: 14px;
		}
	}
	@media screen and (max-width: 1100px) {
		.btn{
			display: none;	
		}
	}
	@media screen and (max-width: 1024px) {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		height: 80px;
		background-color: #fff;
		box-shadow: 0 0 10px rgba(0,0,0,0.2);
		z-index: 9;
		.nav{
			display: none;
			position: fixed;
			top: 80px;
			right: 0;
			left: 0;
			background-color: $color2;
			padding: 15px;
			max-height: calc(100% - 80px);
			>li{
				>a{
					padding: 7px 0;
				}
				&.active{
					a{
						color: #333333;
					}
				}
			}
		}
		.col--phone{
			display: none;
			position: fixed;
			top: 80px;
			right: 0;
			left: 0;
			max-height: calc(100% - 80px);
			text-align: center;
			background-color: $color2;
			padding: 10px;
			a{
				color: #fff;
				margin: 5px 0;
				display: block;
				font-size: 16px;
				span{
					font-family: $regular;
				}
			}
			.more--btn{
				display: none;
			}
			&:after{
				display: none;
			}
			ul{
				position: relative;
				padding: 0;
				display: block;
				background-color: $color2;
				box-shadow: none;
				left: 0;
			}
		}
		.logo{
			position: absolute;
			top: 9px;
			right: 0;
			left: 0;
			margin: 0 auto;
			height: 65px;
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 20px;
			top: 25px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: $color;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.phone--btn{
			position: absolute;
			right: 20px;
			top: 30px;
			font-size: 22px;
			font-family: 'FontAwesome';
			color: $color;
			&:after{
				content: '\f095';
			}
			&.open{
				&:after{
					content: '\f00d';
				}
			}
		}
	}
}
/* header */