/* footer */
footer{
    .footer-top{
        background: url(../img/footer-bg.png) no-repeat center center;
        background-size: cover;
        position: relative;
        padding: 80px 0; 
        // &:after{
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     left: 0;
        //     bottom: 0;
        //     background-color: rgba(51,51,51,0.8);
        // }
        .container{
            display: flex;
            flex-wrap: wrap;
            z-index: 1;
            position: relative;
            justify-content: space-between
        }
        .footer-col{
            max-width: 300px;
            color: #fff;
            margin: 0 15px;
            a{
                color: #fff;
                display: block;
            }
            h3{
                font-size: 18px;
                font-family: $semibold;
                color: #fff;
                text-transform: uppercase;
                margin: 0 0 15px 0;
            }
            ul{
                li{
                    a{
                        color: #fff;
                        margin-bottom: 10px;
                        &:hover{
                            color: $color2;
                        }
                    }
                }
            }
            &.f-social{
                ul{
                    @include ul-default;
                    display: flex;
                    li{
                        a{
                            margin: 0 12px;
                            i{
                                color: $color2;
                                font-size: 18px;
                                transition: 300ms;
                            }
                            &:hover{
                                i{
                                    color: $color;
                                }
                            }
                        }
                    }
                }
            }
            .logo{
                width: 160px;
                height: 80px;
                >img{
                    @include contain;
                }
            }
            &.f-contacts{
                a{
                    margin-bottom: 10px;
                }
            }
            .col--address,
            .col--phone,
            .col--email,
            .col--mobile{
                &:after{
                    color: $color2;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            padding: 50px 0;
            .footer-col{
                margin: 15px;
            }
        }     
        @media screen and (max-width: 1024px) {
            padding: 25px 0;
        }
        @media screen and (max-width: 768px) {
            .footer-col{
                &.f-nav{
                    display: none;
                }
                &.f-logo{
                    display: none;
                }
            }
        }
        @media screen and (max-width: 568px) {
            .footer-col{
                h3{
                    font-size: 16px;
                }
            }
        }
    }
    .footer-bottom{
        padding: 20px 0;
        background-color: #333333;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                font-size: 14px;
                color: #becbd9;
                margin: 0;
                span{
                    color: $color2;
                }
            }
            .artmedia{
                position: relative;
                padding-right: 130px;
                color: #becbd9;
                font-size: 14px;
                &:hover{
                    color: $color2;
                }
                >img{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }
            }
        }
        @media screen and (max-width: 768px){
            .container{
                display: block;
                text-align: center;
                p{
                    margin-bottom: 10px;
                    font-size: 12px;
                }
                .artmedia{
                    font-size: 12px;
                }
            }
        }
    }
}
/* footer */