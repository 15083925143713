/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		background: url(../img/slider-bg.png) no-repeat center center;
		background-size: cover;
		height: 100vh;
		>img {
			display: block;
			position: absolute;
			left: 40%;
			margin-left: -765px;
			bottom: 0;
			max-width: 1200px;
			max-height: 755px;
			object-fit: contain;
			object-position: center center;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
            margin: 0 auto;
            transform: translateY(-50%);
		}
		.slider--text{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 50%;
			margin-right: -650px;
			.slider_form{
				padding: 50px 45px;
				background-color: rgba(248,247,243,0.8);
			}
			form{
				width: 560px;
			}
			h3{
				font-size: 52px;
				text-align: right;
				margin: 0 0 40px 0;
			}
			p{
				text-align: center;
				margin: 0 0 30px 0;
				line-height: 1.5;
			}
			.btn{
				margin: 0 auto;
			}
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid $color;
                transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: $color;
				}
			}
			&.slick-active {
				button {
					background-color: $color;
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-slide{
			height: 800px;
			.slider--text{
				h3{
					font-size: 42px;
				}
			}
		}
	}
	@media screen and (max-width: 1366px) {
		.slick-slide{
			height: 750px;
			>img{
				max-height: 650px;
				left: 0;
				margin-left: 0;
			}
			.slider--text{
				right: 15px;
				margin-right: 0;
				h3{
					font-size: 36px;
					margin: 0 0 15px 0;
				}
				p{
					font-size: 14px;
					margin: 0 0 20px 0;
				}
				.slider_form{
					padding: 30px 20px;
				}
				form{
					width: 520px;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-slide{
			height: 650px;
		}
	}
	@media screen and (max-width: 950px) {
		.slick-slide{
			height: 600px;
			>img{
				max-height: 500px;
				max-width: 800px;
			}
			.slider--text{
				h3{
					font-size: 32px;
					margin: 0 0 15px 0;
				}
				p{
					font-size: 12px;
					margin: 0 0 20px 0;
					max-width: 500px;
				}
				.slider_form{
					padding: 30px 20px;
				}
				form{
					width: 400px;
				}
			}
		}
	}	
	@media screen and (max-width: 768px) {
		.slick-slide{
			height: 500px;
			>img{
				max-height: 400px;
				max-width: 100%;
			}
			.slider--text{
				left: 50%;
				right: auto;
				transform: translateY(-50%) translateX(-50%);
				h3{
					font-size: 28px;
					text-align: center;
				}
				p{
					font-size: 12px;
					margin: 0 0 20px 0;
					max-width: 500px;
				}
				.slider_form{
					padding: 30px 20px;
				}
				form{
					width: 400px;
				}
			}
		}
	}
	@media screen and (max-width: 420px) {
		.slick-slide{
			height: 450px;
			.slider--text{
				h3{
					font-size: 22px;
				}
				.slider_form{
					padding: 15px;
				}
				form{
					width: 280px;
				}
			}
		}
	}
}
/* main slider */
.about{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 70px 0;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		top: 80px;
		max-width: 1500px;
		margin: 0 auto;
		bottom: 80px;
		border: 2px solid $color2;
		left: 0;
		right: 0;
		z-index: -1;
	}
	.left{
		width: 50%;
		display: flex;
		justify-content: flex-end;
	}
	.text{
		width: 590px;
		padding: 75px 0;
		position: relative;
		z-index: 1;
		h1{
			strong{
				display: block;
				margin: 0 0 15px 0;
			}
		}
		p{
			line-height: 1.4;
		}
	}
	.right{
		width: 50%;
		height: 660px;
		padding: 0 0 0 50px;
		display: flex;
		align-items: center;
		>img{
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	@media screen and (max-width: 1499px){
		&:after{
			margin: 0 15px;
		}
	}
	@media screen and (max-width: 1366px){
		.text{
			padding: 50px 0;
		}

	}
	@media screen and (max-width: 1200px){
		padding: 50px 15px;
		.right{
			padding: 0 0 0 20px;
		}
		.text{
			width: 100%;
			padding: 0 0 0 15px;
		}
	}
	@media screen and (max-width: 950px){
		&:after{
			top: 20px;
			bottom: 20px;
		}
		.left{
			width: 100%;
		}
		.right{
			width: 100%;
			height: 400px;
			padding: 0;
			justify-content: center;
		}
		.text{
			padding: 0 15px;
		}
	}
	@media screen and (max-width: 768px){
		.right{
			height: 250px;
		}
	}
}

.video_block{
	height: 530px;
	position: relative;
	>iframe{
		width: 100% !important;
		height: 100% !important;
	}
	.video_bg{
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.6);
		}
		>img{
			@include cover;
		}
		.play-btn{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background: url(../img/play-btn.png) no-repeat center center;
			background-size: contain;
			width: 38px;
			height: 40px;
			margin: 0 auto;
			left: 0;
			right: 0;
			cursor: pointer;
			z-index: 1;
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translate(-50%,-50%);
				right: 0;
				left: 50%;
				bottom: 0;
				background-color: rgba(255,255,255,0.5);
				border-radius: 100%;
				width: 100px;
				height: 100px;
			}
		}
		p{
			color: #fff;
			text-align: center;
			font-size: 24px;
			z-index: 1;
			position: absolute;
			left: 0;
			right: 0;
			top: 55%;
			margin: 30px 0 0 0;
			font-family: $medium;
		}
	}
	.close{
		opacity: 0;
		&:before, &:after{
			background-color: #fff;
		}
	}
	&.video_play_bg{
		.video_bg{
			opacity: 0;
			z-index: -1;
		}
		.close{
			opacity: 1;
		}	
	}
	@media screen and (max-width: 1024px){
		height: 480px;
	}
	@media screen and (max-width: 768px){
		height: 400px;
	}
	@media screen and (max-width: 568px){
		height: 300px;
		.video_bg{
			p{
				font-size: 20px;
				padding: 0 15px;
			}
		}
	}
}

.product{
	background: url(../img/product-bg.png) no-repeat center center;
	background-size: cover;
	.product--list{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.product_block{
		width: calc(33.33% - 30px);
		height: 480px;
		position: relative;
		margin: 0 15px;
		>img{
			@include cover;
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.5);
		}
		&:after{
			content: '';
			position: absolute;
			top: 14px;
			right: 14px;
			left: 14px;
			bottom: 14px;
			border: 2px solid $color2;
			transition: 300ms;
		}
		.text{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			padding: 0 20px;
			left: 0;
			right: 0;
			text-align: center;
			z-index: 1;
			p{
				font-size: 36px;
				font-family: $regularBos;
				color: #fff;
			}
			.btn{
				margin: 0 auto;
			}
		}
		&:hover{
			&:after{
				opacity: 0;
			}
		}
	}
	@media screen and (max-width: 1200px){
		.product_block{
			width: calc(33.33% - 20px);
			margin: 0 10px;	
			.text{
				p{
					font-size: 30px;
				}
			}
		}
	}
	@media screen and (max-width: 1024px){
		.product_block{
			height: 400px;
			.text{
				p{
					font-size: 26px;
				}
				.btn{
					padding: 13px 20px !important;
				}
			}
		}
	}
	@media screen and (max-width: 850px){
		.product_block{
			height: 400px;
			width: calc(50% - 20px);
			margin: 10px;	
			.text{
				p{
					font-size: 22px;
				}
			}
		}
	}
	@media screen and (max-width: 600px){
		.product_block{
			height: 300px;
			width: 100%;
			margin: 10px;	
			.text{
				p{
					font-size: 22px;
				}
			}
		}
	}
	@media screen and (max-width: 420px){
		.product_block{
			height: 250px;
		}
	}
}

.thunders{
	background: url(../img/certif-bg.png) no-repeat center center;
	background-size: cover;
	padding: 30px 0;
	h2{
		color: #fff;
	}
	p{
		color: #fff;
		font-size: 18px;
		span{
			font-family: $semibold;
		}
	}
	.slick-slide{
		margin: 0 15px;
		height: 370px;
		>img{
			@include contain;
		}
	}
	.slick-dots{
		&:before{
			display: none;
		}
	}
	.slick-arrow{
		font-family: 'FontAwesome';
		color: #fff;
		&:before{
			display: none;
		}
		&:after{
			content: "\f105";
			font-size: 16px;
		}
		&.slick-prev{
			left: 50%;
			top: auto;
			bottom: -75px;
			margin-left: -50px;
			&:after{
				content: "\f104";
			}
		}
		&.slick-next{
			right: 50%;
			top: auto;
			bottom: -75px;
			margin-right: -50px;
		}
	}
	.slider-dots{
		text-align: center;
	}
	.current_slide{
		color: #fff;
		font-family: $boldBos;
		font-size: 36px;
		text-align: center;
		transition: 300ms;
	}
	.amount_slide{
		color: #fff;
		font-family: $regularBos;
		font-size: 20px;
		text-align: center;
	}
	@media screen and (max-width: 1200px) {
		padding: 0;
	}
	@media screen and (max-width: 1024px) {
		p{
			font-size: 16px;
		}
	}
	@media screen and (max-width: 568px) {
		.slick-slide{
			height: 300px;
		}
		p{
			font-size: 14px;
		}
	}
}

.address_block{
	overflow-y: auto;
	.container{
		display: flex;
	}
	#map{
		width: 60%;
		height: 750px;
		>ymaps{
			width: 100% !important;
			height: 100% !important;
		}
	}
	.map_adresses{
		width: 40%;
		background-color: #fff;
		padding: 0 0 0 45px;
		h2{
			text-align: left;
		}
		p{
			font-size: 18px;
		}
	}
	.mCustomScrollbar,
	.mCustomScrollbar.mCS_touch_action,
	.mCustomScrollbar.mCS_touch_action .mCS-aviva.mCSB_scrollTools {
		-ms-touch-action: auto;
		touch-action: auto;
	}

	.mCustomScrollBox {
		-ms-touch-action: pan-x;
		touch-action: pan-x;
	}

	.mCustomScrollBox.mCSB_vertical_horizontal {
		-ms-touch-action: auto;
			touch-action: auto;
	}

	.mCustomScrollBox.mCSB_horizontal {
		-ms-touch-action: pan-y;
			touch-action: pan-y;
	}
	.mCustomScrollBox.mCSB_vertical {
		-ms-touch-action: pan-y;
			touch-action: pan-y;
	}
	.address_list{
		max-height: 600px;
		overflow: hidden;
		.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
			background-color: #c2000f;
			width: 6px;
		}
		.mCSB_scrollTools .mCSB_draggerRail{
			background-color: #e8e8e8;
			width: 8px;
		}
	}
	.address_item{
		padding: 27px 10px;
		border-bottom: 1px solid #e8e8e8;
		&:last-child{
			border-bottom: none;
		}
		p{
			margin: 0 0 13px 0;
			font-size: 16px;
			font-family: $semibold;
		}
		.time{
			display: block;
			padding: 0 0 0 25px;
		}
		.col--address{
			cursor: pointer;
		}
		&.active{
			background-color: $color2;
		}
	}
	@media screen and (max-width: 1024px) {
		#map{
			height: 650px;
		}
		.map_adresses{
			padding: 0 0 0 25px;
			.address_list{
				max-height: 520px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.container{
			flex-wrap: wrap;
		}
		#map{
			height: 250px;
			width: 100%;
			order: 2;
			margin: 20px 0 0 0;
		}
		.map_adresses{
			width: 100%;
			padding: 0;
			order: 1;
			p{
				font-size: 16px;
			}
		}
		.address_list{
			max-height: 400px;
		}
		.address_item{
			padding: 15px 0;
		}
	}
}

.contacts_form{
	background-color: #f8f7f3;
	padding: 70px 0;
	.container{
		border: 2px solid $color2;
	}
	.container2{
		padding: 0 15px;
	}
	h2{
		text-align: left;
	}
	p{
		margin: 0px 0 25px 0;
	}
	form{
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		input[type="text"],
		input[type="email"]{
			width: calc(28% - 13px);
			margin-bottom: 0;
		}
	}
	@media screen and (max-width: 1500px){
		padding: 70px 15px;
	}
	@media screen and (max-width: 1280px){
		padding: 50px 15px;
		.container2{
			width: 100%;
		}
		form{
			input[type="text"],
			input[type="email"]{
				width: calc(33.33% - 13px);
				margin-bottom: 15px;
			}
			.btn{
				margin: 0 auto;
			}
		}
	}
	@media screen and (max-width: 1240px){
		form{
			input[type="text"],
			input[type="email"]{
				width: calc(50% - 13px);
			}
		}
	}
	@media screen and (max-width: 600px){
		padding: 25px 15px;
		.container2{
			padding: 0;
		}
		form{
			input[type="text"],
			input[type="email"]{
				width: 100%;
			}
		}
		p{
			margin: 0 0 10px 0;
		}
	}
}
/* main content */